<template functional>
    <v-footer app class="pa-3 app-footer">
        <v-spacer></v-spacer>
        <div>JOB24 &copy; {{ new Date().getFullYear() + ' v.1.6.269' }}</div>
    </v-footer>
</template>

<script>
export default {
  name: 'app-footer',
}
</script>

<style lang="less">
  .app-footer {
    min-height: 28px;
  }
</style>
